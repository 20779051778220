'use strict';

require('./vendor');

//var Isotope = require('isotope-layout/dist/isotope.pkgd.js');

$(document).ready(function () {

  $('input').click(function (e) {
    //e.preventDefault();
    var filterId = $(this).attr('id');

    var filterIDs = $(".filter input:checkbox:checked").map(function () {
      return $(this).attr('id');
    }).get();
    $(".column").fadeOut();

    $(filterIDs).each(function (index, element) {
      $(".column[data-tag='" + element + "']").fadeIn();
    });

    if (filterIDs.length == 0) {
      $(".column").fadeIn();
    };

  });



  // FUNCTIONS
  function reveal() {
    $('.animate').each(function (_, e) {
      if ($(e).offset().top < $(window).scrollTop() + $(window).height() - 300) {
        $(e).addClass('show');
      }
    });
  }

  function scrolled() {
    if ($('html,body').scrollTop() > 1) {
      $('body').addClass('scrolled');
    } else {
      $('body').removeClass('scrolled');
    }
  }
  $('#scroll').click(function (e) {
    e.preventDefault();
  });
  // Faq
  $('.faq-question h4, .faq-icon').click(function (e) {
    e.preventDefault();
    $('.faq-extended').stop();
    parent = $(this).closest('.faq-item');
    if (parent.hasClass('active')) {
      parent.find('.faq-extended').slideUp(100);
      parent.removeClass('active');
    } else {
      $('.faq-extended').slideUp(100);
      $('.faq-item').removeClass('active');
      parent.find('.faq-extended').slideDown(100);
      parent.addClass('active');
    }
  });

  // Initial load
  $('.faq-extended').slideUp(100);
  $('.faq-item').removeClass('active');

  $('.bxslider').bxSlider({
    pager: false,
    autoReload: true,
    breaks: [{
      screen: 0,
      slides: 1,
      pager: false
    }, {
      screen: 460,
      slides: 2
    }, {
      screen: 768,
      slides: 3
    }]
  });

  $('.bxslider2').bxSlider({
    pager: false,
    autoReload: true,
    slideMargin: 30,
    breaks: [{
      screen: 0,
      slides: 1,
      pager: false
    }, {
      screen: 460,
      slides: 1
    }, {
      screen: 768,
      slides: 2
    }, {
      screen: 1024,
      slides: 3
    }]
  });

  $('#menu').click(function (e) {
    e.preventDefault();
    $('body').toggleClass('menu-open');
  });

  $('.nav-close').not('#menu').click(function (e) {
    e.preventDefault();
    $('body').removeClass('menu-open');
  });

  function createFullpage() {
    $('.fullpage').fullpage({
      verticalCentered: false,
      setAutoScrolling: false,
      navigation: true,
      navigationPosition: 'right',
      slidesNavigation: false,
      controlArrows: true
    });
  }

  function resizing() {
    if ($(window).width() < 768) {
      $.fn.fullpage.destroy('all');
    } else {
      if ($('.fullpage').hasClass('fp-destroyed')) {
        createFullpage();
      }
    }
  }

  function changeLogo() {
    if (window.location.pathname != '/') {
      if ($('#logo').offset().top > $('.header').outerHeight() - 50) {
        $('body').removeClass('normal');
      } else if ($('#logo').offset().top < $('.header').outerHeight() + 50) {
        $('body').addClass('normal');
      }
    }
  }



  // INIT
  //reveal();
  //createFullpage();
  //resizing();
  changeLogo();

  $(window).resize(function () {
    //resizing();
  });

  // SCROLL
  $(window).scroll(function () {
    // reveal();
    changeLogo();
  });
});

$(document).foundation();